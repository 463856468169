import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import BalanceView from '@/core/components/ui/BalanceView.vue';
// import CurrencyFilter from '@/filters/currency.filter';
import PhaseItemComponent from '@/modules/project-schedule/components/PhaseItemComponent.vue';
import TotalCostComponent from '@/modules/project-schedule/components/TotalCostComponent.vue';
import { PROJECTS_STORE } from '@/store/modules/projects';
export default defineComponent({
    components: {
        PhaseItemComponent,
        TotalCostComponent,
        BalanceView
    },
    props: ['project', 'estimate', 'hideBalance'],
    created() {
    },
    computed: {
        ...mapGetters(PROJECTS_STORE, ['projectAccountBalance']),
        projectQuoteOwner() {
            return this.estimate?.projectQuoteCreator;
        },
        projectQuoterBusiness() {
            return this.estimate?.projectQuoteCreator?.userBusinessDetails;
        },
        totalCost() {
            if (this.estimate?.quotePrice?.total) {
                return this.estimate?.quotePrice?.total;
            }
            return 0;
        },
    },
});
