<template>
    <div class="labeled-thumbnail flex-row ai-c">
       <UserThumbnail
        :borderRadius="borderRadius ? borderRadius : '50px'"
        :width="iconSize ? iconSize : '30px'"
        :height="iconSize ? iconSize : '30px'"
        :key="img"
        :source="img">
        </UserThumbnail>
        <p class="labeled-thumbnail__text default-text" :class="{'bold': boldFont}" v-if="!hasCustomLabel">
          {{label ? label : ''}}
        </p>
        <slot name="custom-label" v-else></slot>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import UserThumbnail from '@/core/components/ui/UserThumbnail.vue';

export default defineComponent({
  props: ['label', 'img', 'borderRadius', 'hasCustomLabel', 'iconSize', 'boldFont'],
  components: {
    UserThumbnail
  }
});
</script>
<style lang="scss" scoped>
.labeled-thumbnail {
  &__text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 156.25% */
    letter-spacing: 0.25px;
  }
  &__text.bold {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }
}
</style>
