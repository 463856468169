<template>
  <div class="chart-container" v-if="currentSelectedProject" :style="{ minWidth: toPx(grids.length * gridWidth) }">
    <div class="grid-container">
      <div class="grid-header" @mouseover="$emit('updateIsScroll', true)" @mouseleave="$emit('updateIsScroll', false)">
        <div v-for="(grid, index) in grids" :key="grid" class="header">
          <div v-if="grid.date === '1' || index === 0" class="header-month">
            {{ grid.month }}
          </div>
          <div class="header-date" :class="{ 'weekend': grid.isWeekend }">
            <span>{{ grid.date }}</span>
            <span>{{ grid.day }}</span>
          </div>
        </div>
      </div>
      <div class="grid-list">
        <div v-for="grid in grids" :key="grid" class="grid"></div>
      </div>
    </div>

    <div class="task-container" :style="{ width: toPx((grids.length - 1) * gridWidth) }">
      <div class="parent-container-limit" :style="{ width: toPx((grids.length - 1) * gridWidth) }">
        <div v-for="phase in phases.projectStages" :key="phase.id" class="task-phase">
          <el-tooltip
              class="box-item"
              effect="dark"
              :content="phase.name"
              placement="top-start"
            >
            <TaskItem
                :axis="x"
                :sticks="['ml','mr']"
                :isActive="true"
                :preventActiveBehavior="true"
                :isDraggable="isChartEditable"
                :isResizable="isChartEditable"
                :snapToGrid="true"
                :gridX="gridWidth"
                :gridY="0"
                :x="getPhaseMargin(phase)"
                :w="getPhaseWidth(phase)"
                :minw="gridWidth"
                :parentLimitation="true"
                class="phase-item"
                @click="onPhaseExpand(v, phase)"
                :style="{ background: phase.color }"
                @dragstop="v => onPhaseDrag(v, phase)"
                @resizestop="v => onPhaseDrag(v, phase)"
              >
              <div class="phase-title flex-row" :style="{ width: toPx(getPhaseWidth(phase) - 50) }">
                {{ phase.name }}
                <ThumbnailCounter :members="getCombinedMembers(phase.projectStageTasks)" />
              </div>
              <el-icon class="cursor-pointer" v-if="phase.projectStageTasks.length > 0" :style="{ color: phase.color, fontSize: '10px' }"><ArrowDown v-if="!phase.expand" /><ArrowUp v-else /></el-icon>
            </TaskItem>
            <!-- <el-button
              size="large"
              class="phase-item"
              :color="phase.color"
              :style="{ width: toPx(getPhaseWidth(phase)), left: toPx(getPhaseMargin(phase)) }"
              @click="phase.expand = !phase.expand"
            >
              <div
                class="phase-title"
                :style="{ width: toPx(getPhaseWidth(phase) - 50) }"
              >{{ phase.name }}</div> <el-icon v-if="phase.projectStageTasks.length > 0" :style="{ color: phase.color, fontSize: '10px' }"><ArrowDown v-if="!phase.expand" /><ArrowUp v-else /></el-icon>
            </el-button> -->
          </el-tooltip>
          <div
            v-show="phase.expand"
            class="task-phase-list"
            :style="{ height: toPx(phase.projectStageTasks.length * 45) }"
          >
            <div class="task-container-limit" :style="{ width: toPx(getPhaseWidth(phase) - 50) }">
              <div
                v-for="(task, i) in phase.projectStageTasks"
                :key="task.id"
                :style="{ position: 'absolute', top: toPx(40 * i) }"
              >
              <!-- <div
                v-for="(task) in phase.projectStageTasks"
                :key="task.id"
              > -->
              <el-tooltip
                  class="box-item"
                  effect="dark"
                  :content="task.name"
                  placement="top-start"
                >
                  <TaskItem
                    axis="x"
                    :sticks="['ml', 'mr']"
                    :isActive="true"
                    :preventActiveBehavior="true"
                    :isDraggable="isChartEditable"
                    :isResizable="isChartEditable"
                    :snapToGrid="true"
                    :gridX="gridWidth"
                    :gridY="0"
                    :x="getTaskMargin(phase, task)"
                    :w="getTaskWidth(phase, task)"
                    :minw="gridWidth"
                    :parentLimitation="false"
                    :h="30"
                    :parentW="phase.containerWidth || getPhaseWidth(phase)"
                    @dragstop="v => onTaskDrag(v, phase, task)"
                    @resizestop="v => onTaskDrag(v, phase, task)"
                    class="task-item"
                    :style="{ background: phase.color }"
                  >
                  <div class="task-title">
                    {{ task.name }}
                  </div>
                </TaskItem>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ArrowDown, ArrowUp } from '@element-plus/icons';
import moment from 'moment';
import { defineComponent } from 'vue';
import TaskItem from 'vue-drag-resize/src/component/vue-drag-resize.vue';
import { mapGetters } from 'vuex';

import ThumbnailCounter from '@/core/components/ui/ThumbnailCounter.vue';
import { ProjectStageTypeEnum } from '@/core/packages/shared-library';
import { PROJECTS_STORE } from '@/store/modules/projects';
import { USERS_STORE } from '@/store/modules/users';

const gridWidth = 60;

export default defineComponent({
  props: ['project', 'estimate', 'colors', 'isScroll'],
  components: {
    ArrowDown,
    ArrowUp,
    TaskItem,
    ThumbnailCounter
  },
  data() {
    return {
      ProjectStageTypeEnum,

      gridWidth,

      phases: []
    };
  },

  watch: {
    estimate: {
      immediate: true,
      deep: true,
      handler(value) {
        this.phases = this.normalizePhases(value);
      }
    }
  },

  created() {
    // disallow reload
    if (!this.currentSelectedProject) {
      this.$router.push(`/projects/${this.$route.params?.projectRefId}/project-details/overview`);
    }
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(PROJECTS_STORE, [
      'currentSelectedProject',
    ]),

    ...mapGetters(['isTradesperson', 'isProjectOwner']),

    isChartEditable() {
      return this.isTradesperson;
    },

    grids() {
      if (this.getBaseStartDate) {
        const gridStartDate = moment(new Date(this.getBaseStartDate), 'YYYY-MM-DD').subtract(1, 'days').toDate();
        const gridEndDate = moment(new Date(this.getBaseStartDate), 'YYYY-MM-DD').add(12, 'months').toDate();
        const getDatesInRange = () => {
          const date = new Date(gridStartDate.getTime());
          date.setDate(date.getDate() + 1);
          const dates = [];
          while (date < gridEndDate) {
            const dateMoment = moment(date);
            dates.push({
              date: dateMoment.format('D'),
              month: dateMoment.format('MMM YYYY'),
              day: dateMoment.format('ddd'),
              isWeekend: date.getDay() === 0 || date.getDay() === 6
            });
            date.setDate(date.getDate() + 1);
          }
          return dates;
        };
        const dates = getDatesInRange();
        return dates;
      }
      return [];
    },
    getBaseStartDate() {
      return this.project.projectQuotes[0].suggestedStartDate;
    }
  },

  methods: {
    normalizePhases(estimate) {
      const newProjectStages = estimate.projectStages.map((phase) => {
        if (phase.stageType === ProjectStageTypeEnum.MATERIALS
          && (!phase.startDate && !phase.totalDuration)) {
          return {
            ...phase,
            // materials does not have a duration, start/end dates at first,
            // this will cause a display issue for the chart,
            // so we need to set at least a default duration
            totalDuration: 7
          };
        }

        return { ...phase };
      });

      return { ...estimate, projectStages: newProjectStages };
    },

    getCombinedMembers(tasks) {
      const members = [];

      tasks.forEach((task) => {
        const { projectStageTaskMembers } = task;

        if (projectStageTaskMembers && projectStageTaskMembers.length) {
          projectStageTaskMembers.forEach((member) => {
            const hasExist = members.find((item) => item.id === member.id);

            if (!hasExist) {
              members.push(member);
            }
          });
        }
      });

      return members;
    },

    toPx(width) {
      return `${width.toString()}px`;
    },
    getStartDateFromTasks(phase, isNullFill = false) {
      const collectionDate = phase.projectStageTasks.map((x) => {
        if (x.startDate === null) {
          if (isNullFill) {
            return null;
          }
          return moment(phase.startDate).toDate();
        }
        return moment(new Date(x.startDate)).toDate();
      });
      return collectionDate.filter((x) => x);
    },
    getEndDateFromTasks(phase, isNullFill = false) {
      const collectionDate = phase.projectStageTasks.map((x) => {
        if (x.startDate === null || (x.duration === null || x.duration === 0)) {
          if (isNullFill) {
            return null;
          }
          return moment(phase.startDate).add(phase.totalDuration, 'days').toDate();
        }
        return moment(new Date(x.startDate)).add(x.duration, 'days').toDate();
      });
      return collectionDate.filter((x) => x);
    },
    getStartDate(phase, task) {
      return task.startDate !== null ? moment(new Date(task.startDate)) : moment(new Date(phase.startDate));
    },
    getEndDate(phase, task) {
      const duration = task.duration > 0 ? task.duration : phase.totalDuration;
      return task.startDate !== null ? moment(new Date(task.startDate)).add(duration, 'days') : moment(new Date(phase.startDate)).add(duration, 'days');
    },
    getPhaseMargin(phase) {
      const { suggestedStartDate } = this.estimate;
      let daysMultiplier = 1;

      if (phase) {
        const startDate = phase.startDate || suggestedStartDate;

        const daysDiff = moment.duration(moment(startDate).startOf('day').diff(moment(new Date(suggestedStartDate)).startOf('day')));
        daysMultiplier = daysDiff.asDays();
      }

      return daysMultiplier * this.gridWidth;
    },
    getPhaseWidth(phase) {
      const totalDuration = phase.totalDuration || 1;

      // not a good logic, this is unused
      // if (!totalDuration) {
      //   const min = Math.min.apply(null, this.getStartDateFromTasks(phase));
      //   const max = Math.max.apply(null, this.getEndDateFromTasks(phase));

      //   const diff = moment.duration(moment(max).startOf('day').diff(moment(min).startOf('day')));
      //   totalDuration = diff.asDays();
      // }

      return totalDuration * this.gridWidth;
    },

    getTaskMargin(phase, task) {
      const { suggestedStartDate } = this.estimate;
      let daysMultiplier = 1;

      if (phase) {
        const daysDiff = moment.duration(moment(task.startDate).startOf('day').diff(moment(suggestedStartDate).startOf('day')));
        daysMultiplier = daysDiff.asDays();

        if (!daysMultiplier) {
          daysMultiplier = 0;
        }
      }

      return daysMultiplier * this.gridWidth;
    },

    getTaskWidth(phase, task) {
      if (phase.totalDuration && phase.totalDuration < task.duration) {
        return phase.totalDuration * this.gridWidth;
      }

      if (task.duration) {
        return (task.duration) * this.gridWidth;
      }

      return phase.totalDuration * this.gridWidth;
    },

    onPhaseExpand(evt, phase) {
      phase.expand = !phase.expand;
    },

    onPhaseDrag(evt, phase) {
      const baseDate = moment(new Date(this.getBaseStartDate));
      const { left, width } = evt;
      const computedDaysLeft = (left / this.gridWidth);
      const computedDaysRight = ((left + (width || this.gridWidth)) / this.gridWidth);
      const totalDuration = computedDaysRight - computedDaysLeft;
      const { projectStages } = this.phases;
      const foundPhase = projectStages.find((projectStage) => projectStage.id === phase.id);
      const foundPhaseIndex = projectStages.findIndex((projectStage) => projectStage.id === foundPhase.id);

      foundPhase.containerWidth = evt.width;

      // if (computedDaysLeft > 0) {
      const newPhaseStartDate = baseDate.add(computedDaysLeft, 'days').format('YYYY-MM-DD');
      const newPhaseEndDate = baseDate.add(totalDuration, 'days').format('YYYY-MM-DD');
      foundPhase.totalDuration = totalDuration;
      foundPhase.startDate = newPhaseStartDate;
      foundPhase.endDate = newPhaseEndDate;
      projectStages[foundPhaseIndex].projectStageTasks = foundPhase.projectStageTasks.map((projectStageTask) => {
        const item = {
          ...projectStageTask,
          // startDate: newPhaseStartDate,
          // endDate: newPhaseEndDate
        };

        // if material items does not have duration + start/end, just use the phase
        if (projectStages[foundPhaseIndex].stageType === ProjectStageTypeEnum.MATERIALS) {
          let taskDuration = projectStages[foundPhaseIndex].totalDuration;

          if (projectStageTask.startDate && projectStageTask.endDate) {
            const taskDiff = moment.duration(moment(projectStageTask.endDate).startOf('day').diff(moment(projectStageTask.startDate).startOf('day')));

            if (taskDiff.asDays()) {
              taskDuration = taskDiff.asDays();
            }
          }
          // eslint-disable-next-line no-unused-expressions
          item.startDate = projectStageTask.startDate || newPhaseStartDate;
          item.endDate = projectStageTask.endDate || newPhaseEndDate;
          item.duration = taskDuration || projectStages[foundPhaseIndex].totalDuration;
        }

        return item;
      });

      this.phases.projectStages = projectStages;

      const newEstimate = {
        ...this.phases,
        projectStages,
        suggestedStartDate: this.estimate.suggestedStartDate
      };

      this.$emit('update-estimate', JSON.stringify(newEstimate));
    },

    onTaskDrag(v, projectStage, projectStageTask) {
      const phaseId = projectStage.id;
      const taskId = projectStageTask.id;
      const duration = v.width / this.gridWidth;
      const parseStages = JSON.parse(JSON.stringify(this.phases.projectStages));

      const baseDate = moment(new Date(this.getBaseStartDate));
      const { left, width } = v;
      const computedDaysLeft = (left / this.gridWidth);
      const computedDaysRight = ((left + width) / this.gridWidth);
      const totalDuration = computedDaysRight - computedDaysLeft;

      // onPhaseExpand mutates the material items, but we should only trigger if it is dragged
      if (!left && !width) {
        return;
      }

      const projectStages = parseStages.map((phase) => {
        if (phase.id === phaseId) {
          phase.projectStageTasks.map((task) => {
            if (task.id === taskId) {
              // recording another holder for start/end to test inconsistencies
              let newTaskStartDate = baseDate.add(computedDaysLeft, 'days').format('YYYY-MM-DD');
              let newTaskEndDate = baseDate.add(totalDuration, 'days').format('YYYY-MM-DD');
              let startDate = moment(new Date(this.getBaseStartDate)).add(v.left / this.gridWidth, 'days').format('YYYY-MM-DD');
              let endDate = moment(startDate).add(duration, 'days').format('YYYY-MM-DD');

              // boundary locking
              if (startDate < phase.startDate || newTaskStartDate < phase.startDate) {
                newTaskStartDate = phase.startDate;
                startDate = phase.startDate;
              }

              if (newTaskEndDate > phase.endDate || newTaskEndDate > phase.endDate) {
                newTaskEndDate = phase.endDate;
                endDate = phase.endDate;
              }

              // reset
              if (endDate <= startDate || newTaskEndDate <= newTaskStartDate) {
                newTaskEndDate = phase.endDate;
                endDate = phase.endDate;
              }

              if (startDate >= endDate || newTaskStartDate >= newTaskEndDate) {
                newTaskStartDate = phase.startDate;
                startDate = phase.startDate;
              }

              return Object.assign(task, {
                duration,
                startDate,
                endDate,
                // actualStartDate: newTaskStartDate,
                // actualEndDate: newTaskEndDate
              });
            }
            return task;
          });
          // const min = Math.min.apply(null, this.getStartDateFromTasks(phase));
          // const max = Math.max.apply(null, this.getEndDateFromTasks(phase, true));
          // const diff = moment.duration(moment(max).startOf('day').diff(moment(min).startOf('day')));

          return {
            ...phase,
            // startDate: moment(min).format('YYYY-MM-DD'),
            // endDate: moment(max).format('YYYY-MM-DD'),
            // totalDuration: diff.asDays(),
          };
        }
        return phase;
      });
      // const suggestedDate = Math.min.apply(null, projectStages.map((phase) => moment(new Date(phase.startDate)).toDate()));
      // const newEstimate = {
      //   ...this.phases,
      //   suggestedStartDate: moment(suggestedDate).format('YYYY-MM-DD'),
      //   projectStages,
      // };
      this.$emit('update-estimate-tasks', projectStages);
    },
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.chart-container {
  display: flex;
  position: relative;
  height: calc(100vh - 310px);

  .grid-container {
    position: absolute;
    padding-left: 30px;

    .grid-header {
      z-index: 1;
      display: flex;
      align-items: center;
      top: 0;
      position: sticky;

      .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 11px;
        font-style: normal;
        font-weight: 400;
        font-size: 13px;

        .header-month {
          top: 0;
          position: absolute;
          white-space: nowrap;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #0C0F4A;
        }

        .header-date {
          user-select: none;
          width: 60px;
          margin-top: 22px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 10px;

          &:nth-child(1) {
            font-size: 10px;
            line-height: 16px;
            color: rgba(12, 15, 74, 0.5);
          }
          &:nth-child(2) {
            font-size: 8px;
            line-height: 16px;
            color: rgba(12, 15, 74, 0.5);
          }

        }

        .weekend span {
          color: #FAA100 !important;
        }

        &:nth-child(1) {
          margin-left: calc(-60px / 2) !important;
        }
      }

    }

    .grid-list {
      display: flex;

      .grid {
        min-height: calc(100vh - 370px);
        width: 59px;
        border-left: 1px solid rgba(12, 15, 74, 0.1);
      }
    }
  }

  .task-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin-top: 112px;
    margin-left: 30px;

    .task-phase {
      position: relative;
      display: flex;
      flex-direction: column;
      min-height: 42px;
      margin-top: 30px;

      :deep(.el-button) {
        color: #FFFFFF;
        height: 42px;
        padding: 12px;
        font-size: 12px;
      }

      :deep(.phase-item) {
        top: 0;
        position: absolute;
        border-radius: 8px;
        max-height: 42px;
        height: 42px;

        & > span {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        & .el-icon {
          background: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          min-width: 18px;
        }

        .content-container {
          display: flex;
          height: 100%;
          max-height: 42px;
          justify-content: space-between;
          align-items: center;
          padding: 0 12px;
          box-sizing: border-box;
        }
      }

      :deep(.phase-title) {
        text-align: left;
        font-family: 'Mulish';
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: #FFF;
      }

      .task-phase-list {
        margin-top: 42px;
        position: relative;
        display: flex;
        flex-direction: column;

        :deep(.el-button--small) {
          min-height: unset;
          border-radius: 100px;
        }

        .task-item {
          top: 0 !important;
          margin-top: 10px;
          border-radius: 100px;
          transition: all .1s ease-out;

          &::before {
            outline: none !important;
          }

          :deep(.content-container) {
            display: flex;
            align-items: center;
            height: 30px !important;
            max-height: 30px;
            cursor: pointer;
            transition: all .1s ease-out;

            .task-title {
              padding: 0 15px;
              color: #FFFFFF;
              font-size: 12px;
              text-align: left;
              font-family: Arial, Helvetica, sans-serif;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          :deep(.vdr-stick) {
            display: none;
            background: #FFFFFF;
            border: 1px solid grey;
            border-radius: 50%;
          }

          &:hover {
            :deep(.vdr-stick:not(.not-resizable)) {
              display: block;
            }
          }
        }

        & :nth-child(1) > button {
          margin-top: 16px !important;
        }
      }
    }

    .dragging {
      opacity: 0.5;
    }
  }
}

@include media-md-max-width() {
  .chart-container {
    height: calc(100vh - 334px);
    overflow-y: scroll;
    .grid-container {
      height: 100vh;
      box-sizing: border-box;
      .grid-header {
        background: #F1F6FB;
        z-index: 2;
      }
      .grid-list {
        .grid {
          height: 100vh;
        }
      }
    }

    .task-container {
      margin-top: 40px;
      margin-left: 30px;
    }
  }
}

@include media-sm-max-width() {
  .chart-container {
    height: calc(100vh - 395px);
    overflow-y: hidden;
    .grid-container {
      height: 100%;
    }
    .task-container {
      margin-top: 67px;
      overflow-y: auto;
    }
  }
}

@include media-xs-max-width() {
  .chart-container {
    height: calc(100vh - 375px);
  }
}
</style>
