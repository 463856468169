<template>
    <div class="back-anchor flex-row ai-c jc-c" @click="back">
      <el-icon :size="13" color="#0C0F4A"><ArrowLeftBold/></el-icon>
    </div>
</template>
<script>
import { ArrowLeftBold } from '@element-plus/icons';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    ArrowLeftBold,
  },

  emits: ['back'],

  props: {
    isNotRouteLevel: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    back() {
      if (this.isNotRouteLevel) {
        this.$emit('back');
      } else {
        this.$router.go(-1);
      }
    }
  },
});
</script>
<style lang="scss" scoped>
.back-anchor {
    background: #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: .2rem;
    cursor: pointer;
    min-width: 30px;
    box-shadow: 0px 0px 10px rgba(42, 42, 42, 0.02);
}
</style>
