<template>
  <header class="global-header flex-row ai-c jc-sb">
    <div class="global-header__wrapper flex-row ai-c" style="gap: .5rem;">
      <p class="global-header__header-text default-text flex-row ai-c">
        {{title}}
        <span class="count flex-row ai-c jc-c" v-if="count">{{count}}</span>
      </p>
      <slot name="start-custom-component" v-if="hasStartCustomComponent"></slot>
    </div>

    <div class="global-header__wrapper flex-row ai-c" v-if="hasEndCustomComponent">
      <slot name="end-custom-component"></slot>
    </div>
  </header>
</template>
<script>
export default {
  props: ['title', 'hasEndCustomComponent', 'count', 'hasStartCustomComponent']
};
</script>
<style lang="scss" scoped>
@use "../../../../assets/scss/mixins/media-query" as *;
@use "../../../../assets/scss/variables/layout" as *;

.global-header {
  height: 42px;
  width: 100%;
  &__header-text {
    font-size: 28px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    gap: .5rem;

    .count {
      width: 30px;
      height: 30px;
      border-radius: 16px;
      box-sizing: border-box;
      border: 1px solid #FFA500;
      text-align: center;
      font-size: 18px;
    }
  }
}
@include media-sm-max-width() {
  .global-header {
    height: auto;
    &__header-text {
      font-size: 22px;
      line-height: 22px;
    }
  }
}
@include media-xs-max-width() {
  .global-header {
    &__header-text {
      font-size: 20px;
    }
  }
}
</style>
