<template>
    <div class="cost-container flex-row ai-c jc-sb">
        <p class="cost-container__label default-text">Total cost</p>
        <p class="cost-container__value default-text">{{ totalCost ? $filters.formatToCurrency(totalCost) : 'N/A' }}</p>
    </div>
</template>
<script>
export default {
  props: ['totalCost']
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.cost-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    color: #fff;
    background: #3639AF;
    border-radius: 8px;
    padding: 0px 20px;

    &__label {
        font-weight: 400;
        font-size: 12px;
        color: #FFFFFF;
    }

    &__value {
        font-weight: 700;
        font-size: 12px;
        color: #FFFFFF;
    }
}

@include media-sm-max-width() {
    .cost-container {
       width: calc(100% - 40px);
    }
}
@include media-xs-max-width() {
    .cost-container {
       width: calc(100% - 43px);
    }
}
</style>
