<template>
    <div class="estimate-summary-boxes flex-row ai-c">
        <SingleDataWidget
          :isLightMode="isLightMode"
          :data="$filters.formatToDate(projectStartDate)"
          width="100%"
          label="Project start date"/>
        <SingleDataWidget
          :isLightMode="isLightMode"
          :data="totalPhaseCount"
          width="100%"
          label="Total phases"/>
        <SingleDataWidget
          :isLightMode="isLightMode"
          :data="totalDuration + ' day(s)'"
          width="100%"
          label="Total duration"/>
        <SingleDataWidget
          :isLightMode="isLightMode"
          :data="$filters.formatToCurrency(estimate?.quotePrice?.total)"
          width="100%"
          label="Total cost"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';

import SingleDataWidget from '@/core/components/ui/SingleDataWidget.vue';
import { PROJECT_QUOTES_STORE } from '@/store/modules/project-quotes';

export default defineComponent({
  components: {
    SingleDataWidget
  },
  props: ['estimate', 'isLightMode'],

  data() {
    return {
      totalDuration: 0
    };
  },

  computed: {
    totalPhaseCount() {
      return this.estimate?.projectStages.length.toString();
    },
    projectStartDate() {
      return this.estimate?.suggestedStartDate;
    },
  },

  created() {
    this.getTotalPhaseDuration();
  },

  methods: {
    ...mapActions(PROJECT_QUOTES_STORE, [
      'getTotalDuration'
    ]),

    async getTotalPhaseDuration() {
      this.totalDuration = await this.getTotalDuration(this.estimate?.projectStages);
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.estimate-summary-boxes {
  width: 100%;
}

@include media-sm-max-width() {
  .estimate-summary-boxes {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
}
</style>
