import { defineComponent } from 'vue';
const __default__ = defineComponent({
    props: {
        isLightMode: {
            type: Boolean,
            default: false
        },
        data: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        width: {
            type: String,
            default: '150px'
        },
        height: {
            type: String,
            default: '60px'
        },
        gap: {
            type: String,
            default: '0'
        }
    }
});
import { useCssVars as _useCssVars } from 'vue';
const __injectCSSVars__ = () => {
    _useCssVars(_ctx => ({
        "752a6634": (_ctx.$props.width),
        "63c053f2": (_ctx.$props.height),
        "0ce91414": (_ctx.$props.gap)
    }));
};
const __setup__ = __default__.setup;
__default__.setup = __setup__
    ? (props, ctx) => { __injectCSSVars__(); return __setup__(props, ctx); }
    : __injectCSSVars__;
export default __default__;
